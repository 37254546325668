import React, { Component } from "react"

import Input from "src/components/forms/Input"
import "./Download.scss"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class DownloadForm extends Component {
  constructor(props) {
    super(props)
    const { bookId } = props
    this.state = {
      formData: {
        name: "",
        company: "",
        email: "",
        companysize: "",
        book: bookId,
      },
      formErrors: {
        name: "",
        company: "",
        email: "",
        companysize: "",
      },
      hasSubmitted: false,
      disabled: false,
      success: false,
      nameValid: false,
      companyValid: false,
      emailValid: false,
      formValid: false
    }
  }

  validateFields = () => {
    const { formData } = this.state
    const fieldValidationErrors = this.state.formErrors
    
    const nameValid = formData.name.length > 0
    fieldValidationErrors.name = nameValid ? "" : "Please enter your name"

    const companyValid = formData.company.length > 0
    fieldValidationErrors.company = companyValid ? "" : "Please enter your company"

    const emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    fieldValidationErrors.email = emailValid ? "" : "Please enter a valid email"

    this.setState({ 
      formErrors: fieldValidationErrors,
      nameValid,
      companyValid,
      emailValid: Boolean(emailValid),
    }, () => this.validateForm())

    return nameValid && companyValid && emailValid
  }

  validateForm = () => {
    const { nameValid, companyValid, emailValid } = this.state
    this.setState({ formValid: nameValid && companyValid && emailValid })
  }

  resetForm = () => {
    const { bookId } = this.props
    this.state = {
      formData: {
        name: "",
        company: "",
        email: "",
        companysize: "",
        book: bookId,
      },
      formErrors: {
        name: "",
        company: "",
        email: "",
        companysize: "",
      },
      hasSubmitted: false,
      disabled: false,
      success: false,
      nameValid: false,
      companyValid: false,
      emailValid: false,
      formValid: false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ hasSubmitted: true })
    if(this.validateFields()) {
      const form = this.state.formData
      window.analytics.identify({
        name: form.name,
        email: form.email,
        company: {
          name: form.company
        },
      })
      window.analytics.track("DownloadBook", {
        bookId: form.book,
      })
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "book-download", ...form }),
      }).then(() => {
          this.setState({disabled: true, success: true})
          setTimeout(() => {
            this.resetForm()
          }, 5000)
        })
        .catch(error => console.error(error))
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    }, () => { this.validateFields() })
  }

  render() {
    const { disabled, formData, formErrors, formValid, success, hasSubmitted } = this.state
    const { company, companysize, email, name } = formData

    const disabledSubmit = hasSubmitted && !formValid

    return (
      <div className="download-form">
        <form onSubmit={this.handleSubmit}>
          <div className="hidden">
            <label htmlFor="companysize">Don&apos;t fill this out</label>
            <input
              type="text"
              name="companysize"
              id="companysize"
              onChange={this.handleChange}
              value={companysize}
              disabled={disabled}
              className="form-control form-control-lg"
            />
          </div>
          <div className="form-group">
            <Input
              label="Your Name *"
              name="name"
              onChange={this.handleChange}
              value={name}
              disabled={disabled}
              className="form-control form-control-lg"
              error={formErrors.name}
            />
          </div>
          <div className="form-group">
            <Input
              label="Company *"
              onChange={this.handleChange}
              name="company"
              value={company}
              disabled={disabled}
              className="form-control form-control-lg"
              error={formErrors.company}
            />
          </div>
          <div className="form-group">
            <Input
              label="Email *"
              type="email"
              onChange={this.handleChange}
              name="email"
              value={email}
              disabled={disabled}
              className="form-control form-control-lg"
              error={formErrors.email}
            />
          </div>
          {success ? (
            <p>Success! Check your email for your copy.</p>
          ) : (
            <button 
              type="submit" 
              className="btn btn-primary btn-lg"
              disabled={disabledSubmit}
            >
              Get the Book
            </button>
          )}
        </form>
      </div>
    )
  }
}

export default DownloadForm
