import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import DownloadForm from "src/components/forms/Download"
import InsightCarousel from "src/components/insights/Carousel"
import Content from "src/components/Content"

import "./bookTemplate.scss"

const BookTemplate = ({data: {page: {frontmatter: {
  meta, hero, main, bookId,
}}} }) => (
  <Layout meta={meta}>
    <div className="book-template">
      <PageHero {...hero} />
      <section className="book-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-7 offset-md-2">
              <Content html={main.header} />
            </div>
          </div>

          <div className="book-form">
            <div className="row">
              <div className="col-md-4 offset-md-1 order-last order-md-first">
                <img src={main.image} alt={hero.title} />
              </div>

              <div className="col-md-4 offset-md-1 order-first order-md-last">
                <DownloadForm bookId={bookId} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Content html={main.footer} />
            </div>
          </div>
        </div>
      </section>

      <section className="book-resources">
        <div className="container">
          <InsightCarousel cards={[]} />
        </div>
      </section>
    </div>
  </Layout>
)

export default BookTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
      frontmatter {
        bookId
        main {
          header
          image
          footer
        }
      }
    }
  }
`
