import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./Input.scss"

const Input = ({ label, type, name, value, onChange, placeholder, disabled, className, required, error }) => {
  return (
    <div className={classNames({ "has-error": error.length > 0 })}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        required={required}
        disabled={disabled}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

Input.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  error: ""
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
}

export default Input
